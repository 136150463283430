import React, { useEffect } from 'react'
import './estilosModal.css'
import {connect} from 'react-redux'
function Modal(props) {
    if (props.modal) {

        return (
            <div className="Modal">
                <div className="ModalContent">
                    <button className="buttonClose" onClick={() => props.function(false)}>x</button>
                    <h1 className="titleModal">{props.Plantilla.Nota1}</h1>
                    <article>{props.Plantilla.DescripcionNota1}</article>
                </div>
                <div className="ModalCover" onClick={() => props.function(false)}></div>
            </div >
        )
    } else {
        return (false)
    }
}
const PropsStore = state => {
    return {
        Plantilla:state.dataPlantilla.data
    }
}

export default connect(PropsStore,null)(Modal)