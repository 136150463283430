import {DATA,IDIOMA} from '../actions/dataActions'

const default_data={
    data:false,
    idioma:1,
    categorias:[],
}

const dataPlantilla = (state=default_data,action)=>{
    switch(action.type){
        case DATA:{
            return{
                ...state,data:action.payload
            }
        }
        case IDIOMA:{
            return{
                ...state,idioma:action.payload,categorias:state.data.CategoriasMarket.filter((x)=>x.lenguaje==action.payload)
            }
        }
       
        default: return state
    }
}

export default dataPlantilla;