import {ADDPRODUCTO} from '../actions/ProductosActions'

const default_data={
    productos:[],
}

const Productos = (state=default_data,action)=>{
    switch(action.type){
        case ADDPRODUCTO:{
            return{
                ...state,productos:action.payload
            }
        }
        default: return state
    }
}

export default Productos;