import React, { useState, useEffect } from 'react'
import './estilos.css'
import { scroller, animateScroll as scroll } from "react-scroll";
import { connect } from 'react-redux'
import Menu from './modalMenu'

function Navbar(props) {
    const [opacidad, setOpacidad] = useState(1);
    const [menu, setMenu] = useState(1)
    const [modalMenu, setmodalMenu] = useState(false)
    const [cantidad, setcantidad] = useState(0)

    useEffect(async () => {
        document.getElementById('slogan').innerHTML = props.data.Slogan
        window.addEventListener('scroll', () => {
            if ((window.scrollY / window.innerHeight * 2) <= 1) {
                setMenu(1)
                document.getElementById('down').style.display = "block"
                document.getElementById('up').style.display = "none"
            }
            if ((window.scrollY / window.innerHeight * 2) >= 1.3) {
                setMenu(2)
                document.getElementById('down').style.display = "block"
                document.getElementById('up').style.display = "none"
            }
            if ((window.scrollY / window.innerHeight * 2) >= 3) {
                setMenu(3)
                document.getElementById('down').style.display = "none"
                document.getElementById('up').style.display = "block"

            }
        })
        //setcantidad(props.productos.reduce((a,c)=>a+c.cantidad,0))
       
    }, [props.productos])
    const handleClick = () => {
        if (menu === 1) {
            scroller.scrollTo(('Section2').toString());
        }
        else if (menu === 2) {
            scroller.scrollTo(('Section3').toString());
        }
        else if (menu === 3) {
            scroll.scrollToTop();
        }

    }
    /*onst handleMarket = () => {
        setmodalMenu(true)
    }*/
    return (
        <>
            <div className="menu">
                <div className="wrapperMenu">
                    <a href="https://www.greencardpv.com/" className="greenCard colMenu" style={{ opacity: opacidad }}>
                        <img src="../../images/greencard.png" alt="" />
                    </a>
                    <div className="buttonSide" onClick={() => handleClick()}>
                        <i className="fas fa-chevron-down" id="down" style={{ display: 'block' }}></i>
                        <i className="fas fa-chevron-up hiden" id="up" style={{ display: 'none' }}></i>
                    </div>
                    <a style={{ opacity: opacidad }} href={`https://wa.me/${props.data.Whatsapp}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`} className="whatsApp colMenu">
                        <i className="fab fa-whatsapp icon"></i>
                    </a>
                    {/* <div style={{ opacity: opacidad }} className="whatsApp colMenu">
                        <div className="marketButon" onClick={() => handleMarket()}>
                            <label htmlFor="" className="productLabel">{cantidad}</label>
                            <i className="fas fa-shopping-cart"></i>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <Menu data={props.productos} estatus={modalMenu} setmodalMenu={setmodalMenu}/> */}
        </>
    )
}

const PropsStore = state => {
    return {
        data: state.dataPlantilla.data,
        productos: state.Productos.productos
    }
}

export default connect(PropsStore, null)(Navbar)
