import React, { useState, useEffect } from 'react'
import './styles.css'
import { connect } from 'react-redux'
import { ADDPRODUCTO } from '../redux/actions/ProductosActions'
import { idioma_action } from '../redux/actions/dataActions'
import Modal from '../components/modal'
import { Helmet } from "react-helmet"
import NavbarContacto from '../components/navbarContacto'


function Home(props) {
    
    let slideIndex = 0;
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const handleModal = (data) => {
        setData(data)
        setModal(true)
    }
    const handleService = (id) => {
        window.location.hash = "#" + id;
    }

    const slider = () => {
        let i;
        var slides = document.getElementsByClassName("heroImagen");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) { slideIndex = 1 }
        slides[slideIndex - 1].style.display = "block";
        //seformData.append('nombre', file.nombre);tTimeout(showSlides, 2000); // Change image every 2 seconds
    }

    useEffect(async () => {
        console.log(props.data);
        setInterval(() => slider(), 5000);
        if (props.data.activo == "1") {
            if(props.data.Slogan.length>25){
                document.getElementById('slogan').style.fontSize="1.5em";
            }
            document.getElementById('slogan').innerHTML = props.data.Slogan
        }
    }, [])

    const handleShare = async (e, url) => {
        // Evitamos el comportamiento por default del enlace
        e.preventDefault();
        const shareData = {
            title: 'Visita mi greencardpv',
            text: 'Hola aqui encontrarás mi menú digital',
            url: `https://${window.rutasubdominio}/`,
        }
        await navigator.share(shareData)
    }
    const handleProducto = (producto) => {
        const exist = props.productos.find((x) => x.id === producto.id)
        if (exist) {
            props.ADDPRODUCTO(props.productos.map((x) => x.id === producto.id ? { ...exist, cantidad: exist.cantidad + 1 } : x))
        }
        else {
            props.ADDPRODUCTO([...props.productos, {
                id: producto.id,
                imagen: producto.imagen,
                titulo: producto.titulo,
                precio: producto.precio,
                unidad_medida: producto.unidad_medida, 
                cantidad: 1
            }])
        }
    }

    if (props.data.activo == "0") {
        return (
            <div className="wrappContainer" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
                <Helmet>
                    <title>{props.data.titleHtml}</title>
                    <link rel="shortcut icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/${props.data.Favicon}`} sizes="64x64 32x32 24x24 16x16" />
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo192.png`} sizes="192x192"/>
                    <link rel="apple-touch-icon" href={`${window.protocoloNoSSL}${window.dominio}/${window.subdominio}.greencardpv.com/logo512.png`} sizes="512x512"/>
                    <meta name="description" content={`${props.data.titleHtml}`} />
                    <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
                    <style>{props.data.colorBoton}</style>
                </Helmet>
                <div className="section hero" id="Section1">
                    <div className="heroImagen" style={{backgroundImage: `url(https://greencardpv.com/plantilla_greencardpv/estudiomrgreen-greencardpv.jpg)`}}></div>
                </div>
            </div>
        )
    }else{


        return (
            <>
                <div className="wrappContainer" style={{
                        backgroundColor: `#${props.data.ColorDegradado}`
                    }}>
                    <Helmet>
                        <title>{window.subdominio}</title>
                        <link rel="icon" type="image/png" href={`https://greencardpv.com/${window.subdominio}.greencardpv.com/${props.data.Favicon}`} sizes="16x16" />
                        <meta name="description" content={window.subdominio} />
                        <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
                    </Helmet>
                    <div className="section hero" id="Section1">
                        {props.data.TotalSlider.map(hero => {
                                if (hero == 1) {
                                    return (
                                        <div className="heroImagen" key={hero} style={{
                                            backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/hero/${hero.url_logo}")`
                                        }}> </div>
                                    )
                                } else {
                                    return (
                                        <div className="heroImagen" key={hero} style={{
                                            backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/hero/${hero.url_logo}")`
                                        }}> </div>
                                    )
                                }
                            })}
                        <div className="containerHero">
                            <div className="logo">
                                <img src={`https://greencardpv.com/${window.subdominio}.greencardpv.com/images/${props.data.UrlLogo}`} alt="" />
                            </div>
                            <div className="slogan" id="slogan"></div>
                            {props.data.CategoriasMarket.find(categoria => categoria.lenguaje==="2") &&
                                <div>
                                    <nav className="idiomas">
                                <div className="marker"></div>
                                <div className="idiomas-item" onClick={() => props.idioma_action(1)}>Español</div>
                                <div className="idiomas-item" onClick={() => props.idioma_action(2)}>English</div>
                                </nav>
                                </div>
                            }
                            {props.data.Nota1 && <div className="handleCick" onClick={() => handleModal(props.data.Nota1)}>{props.data.Nota1}</div>}
                        
                            <div className="vistas">
                                <i className="fas fa-eye"></i><strong> {props.data.Vistas}</strong>
                            </div>
                        </div>
                        {props.data.Degradado==="1" && 
                            <div className="degradado" style={{ background: `linear-gradient(rgba(255,255,255,0) ${props.data.porcentajeDegradado}%, #${props.data.ColorDegradado})` }}></div>
                        }
                    </div>
                    <div className="section" id="Section2" style={{minHeight: "0"}}>
                        <div className="categorias">
                            {props.categorias.map(categoria => {
                                return (
                                    <div key={categoria.id_cat_mark} style={{ backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/market/${categoria.imagen}")` }}
                                        className="serviceTag"
                                    >
                                        <div className="degradadoTag" style={{ background: `linear-gradient(rgba(255,255,255,0) ${props.data.porcentajeDegradadoCat}%, #000)` }} onClick={() => handleService(categoria.id_cat_mark)}></div>
                                        <div className="ContectService">
                                            <div className="contectDescripcion">{categoria.descripcion}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="section" id="Section3">
                        <NavbarContacto />
                        <div className="productos">
                            {props.categorias.map(categoria => {
                                return (
                                    <div id={categoria.id_cat_mark}>
                                        <h2 className="titulodescripcion">{categoria.descripcion}</h2>
                                        {props.data.Market.filter(producto => producto.id_cat_mark === categoria.id_cat_mark && producto.activo == '1').map(producto => {
                                            return (
                                                <div className="producto">
                                                    <div className="producto_imagen"
                                                        style={{ backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/market/${producto.imagen}")` }}
                                                    >
                                                    </div>
                                                    <div className="producto_nombre">
                                                        <strong>{producto.titulo}</strong>
                                                        <article className="descripcionProducto">{producto.descripcion}</article>
                                                        <br />
                                                        <strong className="producto_precio">${producto.precio}</strong>
                                                    </div>
                                                    {props.data.Carrito=="" && 
                                                    <div className="productosumar" onClick={() => handleProducto(producto)}>
                                                        <div style={{ color: `#${props.data.ColorDegradado}` }}><i className="fas fa-plus"></i></div>
                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="contentMedia">
                            <div className="socialMedia">
                                {props.data.Telefono && <a href={`tel:${props.data.Telefono}`} className="socialIcon"><i className="fa fa-phone contactIcon"></i></a>}
                                {props.data.Ubicacion && <a href={props.data.Ubicacion} className="socialIcon"><i className="fas fa-map-marker contactIcon"></i></a>}
                                {props.data.Facebook && <a href={props.data.Facebook} className="socialIcon"><i className="fab fa-facebook contactIcon"></i></a>}
                                {props.data.Instagram && <a href={props.data.Instagram} className="socialIcon"><i className="fab fa-instagram contactIcon"></i></a>}
                                <a href="" onClick={(eve) => handleShare(eve, `https://${window.subdominio}.greencardpv.com/`)} className="socialIcon"><i className="fas fa-share-alt contactIcon"></i></a>
                            </div>
                            <div className="greenCard">
                                <strong>{window.rutasubdominio}</strong>
                            </div>
                        </div>
                    </div>
                    <Modal modal={modal} data={data} function={setModal} />
                </div>
                

            </>
        )
    }
}
const PropsStore = state => {
    return {
        data: state.dataPlantilla.data,
        productos: state.Productos.productos,
        categorias: state.dataPlantilla.categorias,
    }
}
const functionStore = {
    ADDPRODUCTO,
    idioma_action
}

export default connect(PropsStore, functionStore)(Home)