import React,{useState,useEffect} from 'react'
import './modalMenu.css'
import { connect } from 'react-redux'
import { ADDPRODUCTO } from '../redux/actions/ProductosActions'
function Menuopciones(props) {
    const [state, setstate] = useState(0)
    const [Extra, setExtra] = useState('')
    useEffect(() => {
        setstate(props.producto.cantidad)
    }, [props.producto.cantidad])
    
    const restar = () =>{
        if (state>1) {
            setstate(state-1)
        }
    }
    const eliminarProducto = (producto) => {
        props.ADDPRODUCTO(props.productos.filter((x) => x.id !== producto))
        props.handleOpciones()
    }
    const handleProducto = (producto) => {
        const exist = props.productos.find((x) => x.id === producto.id)
        if (exist) {
            props.ADDPRODUCTO(props.productos.map((x) => x.id === producto.id ? { ...exist, cantidad: state,extra:Extra } : x))
        }
        props.handleOpciones()
    }
    if (props.producto !== null) {
        return (
            <div className="menuOpcioneswrapper hide" id="menuOpcioneswrapper">
                <div className="containerOpciones">
                    <div className="headerOpciones">
                        <div className="backOpciones" onClick={() => props.handleOpciones()}><i className="fas fa-arrow-left"></i></div>
                        <div className="titleOpciones">
                            {props.producto.titulo}
                        </div>
                        <div className="heroOpciones" style={{ backgroundImage: `url("https://greencardpv.com/${window.subdominio}.greencardpv.com/images/market/${props.producto.imagen}")` }}></div>
                    </div>
                    <div className="bodyOpbciones">
                        <strong>¿Deseas agregar una nota?</strong>
                        <div className="notaOpciones">
                            <textarea onChange={(e)=>setExtra(e.target.value)}></textarea>
                        </div>
                        <div className="cantidad">
                            <div className="buttonCantidad" onClick={()=>restar()}><i className="fas fa-minus"></i></div>
                            <div><strong>{state}</strong></div>
                            <div className="buttonCantidad" onClick={()=>setstate(state+1)}><i className="fas fa-plus"></i></div>
                        </div>
                        <div className="buttonEliminarOpciones" onClick={() => eliminarProducto(props.producto.id)}>
                            <div><i className="fas fa-trash"></i> eliminar artículo</div>
                        </div>
                    </div>
                    <div className="footOpciones">
                        <div className="divButton">
                            <div className="ordenarBoton" onClick={()=>handleProducto(props.producto)}>Actualizar carrito + ${state*props.producto.precio}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            null
        )
    }
}

const PropsStore = state => {
    return {
        data: state.dataPlantilla.data,
        productos: state.Productos.productos
    }
}
const functionStore = {
    ADDPRODUCTO,
}

export default connect(PropsStore, functionStore)(Menuopciones)
