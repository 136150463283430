export const DATA = 'DATA'
export const IDIOMA='IDIOMA'

export const data_action = (data) =>{
    return{
        type:DATA,
        payload:data
    }
}

export const idioma_action = (idioma) =>{
    return{
        type:IDIOMA,
        payload:idioma
    }
}
